<template>
    <Modal>
        <template v-slot:modal-content>
            <div class="to-center">
                <div>
                    <div class="success-icon"></div>
                    <div class="message">
                        {{ message }}
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
    name: 'SuccessModal',
    emits: ['update:modelValue'],
    components: {
        Modal
    },
    props: {
        message: {
            type: String,
            default: '已完成'
        }
    },
    methods: {
        showWhile: function () {
            window.setTimeout(() => {
                this.$emit('update:modelValue', false)
            }, 1500)
        }
    },
    updated() {
        this.showWhile()
    }
}
</script>

<style lang="scss" scoped>
.success-icon {
    height: 54px;
    margin-bottom: 10px;
}

.message {
    color: $seventh-black;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 21px;
}
</style>
