<template>
    <div @click="turnOnModal()" class="get-zip-password">
        我不知道壓縮檔密碼？
    </div>
    <Modal :title="title" v-model="isShowModal">
        <template v-slot:modal-content>
            <div class="alert-word" v-html="message" />
            <div class="alert-word" v-html="message2" />
            <div class="alert-word" v-html="message3" />
            <div v-if="$isAdmin()" class="alert-word">
                {{ message4 }} <br />
                {{ message5 }} <br />
                <div class="email-container">
                    <div class="email">{{ email }}</div>
                    <Button
                        buttonSize="small"
                        buttonWord="重新整理"
                        buttonStyle="hollow"
                        @click="refreshMemberInfo"
                    />
                </div>
            </div>
            <a
                v-if="$isAdmin()"
                :href="labUrl"
                target="_blank"
                class="wrong-email"
                @click="$setGaEvent('clickWrongEmailLink', 'click-Link')"
            >
                {{ wrongEmailMessage }}
            </a>
        </template>
        <template v-slot:modal-bottom>
            <Button
                v-if="!$isAdmin()"
                buttonWord="我知道了"
                buttonStyle="blue"
                @click="turnOffModal"
            />
            <Button
                v-if="$isAdmin()"
                buttonWord="取消"
                buttonStyle="grey"
                @click="turnOffModal"
            />
            <Button
                v-if="$isAdmin()"
                buttonWord="寄送密碼"
                buttonStyle="blue"
                @click="sendZipPasswordToAdmin"
            />
        </template>
    </Modal>
    <SuccessModal v-model="isShowSuccessModal" message="已完成" />
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import SuccessModal from '@/containers/SuccessModal.vue'
import {
    getUserInfoAPI,
    sendZipPasswordToAdminAPI
} from '@/assets/javascripts/api'

export default {
    name: 'ZipPasswordAlert',
    components: {
        Modal,
        Button,
        SuccessModal
    },
    methods: {
        turnOnModal: function () {
            this.isShowModal = true
            this.$setGaEvent('forgetPasswordLink', 'click-Link')
        },
        turnOffModal: function () {
            this.isShowModal = false
        },
        refreshMemberInfo: async function () {
            this.$showLoading()
            const res = await getUserInfoAPI()
            const userInfo = res.data
            this.storeUserInfo(userInfo)
            this.$hideLoading()
        },
        storeUserInfo: function (userInfo) {
            this.$store.commit('set', {
                userInfo: userInfo
            })
        },
        sendZipPasswordToAdmin: async function () {
            this.$showLoading()
            this.$setGaEvent('sendZipPasswordToAdmin', 'click-Button')
            try {
                await sendZipPasswordToAdminAPI()
                this.turnOffModal()
                this.isShowSuccessModal = true
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.$showErrorMessage(this.errorMessage)
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        email: function () {
            const userInfo = this.$store.state.userInfo
            return userInfo ? userInfo.email : ''
        }
    },
    data() {
        return {
            isShowModal: false,
            isShowSuccessModal: false,
            title: this.$isAdmin()
                ? '確定要寄送密碼到您的信箱嗎？'
                : '若佣金明細檔需要密碼',
            message: '1. 業務員個人明細壓縮檔:',
            message2: `自2021年2月份以後的佣金壓縮檔案</br>密碼為${
                this.$isAdmin() ? '夥伴' : '您'
            }PKS 登入「帳號」的數字 9 碼。</br>例：帳號為：A123456789_A，則密碼為：123456789。`,
            message3: `提醒您:</br>2021年1月份以前的佣金壓縮檔案</br>密碼為${
                this.$isAdmin() ? '夥伴' : '您'
            }「PKS登入帳號」。</br>例：帳號為：A123456789_A，則密碼為：A123456789_A。`,
            message4: '2. 全佣金明細壓縮檔: ',
            message5:
                '若您不知道或忘記全佣金明細壓縮檔的密碼，請按下寄送按鈕，密碼將會寄以下信箱，請務必確認收件地址正確：',
            wrongEmailMessage: '這不是我的信箱？我要去Lab修改',
            errorMessage: '寄送密碼時出現錯誤..建議您重新整理頁面',
            labUrl: process.env.VUE_APP_LAB_URI
        }
    }
}
</script>

<style lang="scss" scoped>
.get-zip-password {
    margin-right: 20px;
    text-decoration: underline;
    cursor: pointer;
}

.alert-word {
    margin-bottom: 15px;
    font-size: 14px;
    color: $secondary-grey;
    .email-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .email {
            margin: 10px 10px 0 0;
            font-size: 16px;
            font-weight: 700;
            text-decoration: underline;
        }
        button {
            margin: 10px 0 0 0;
            &:hover,
            &:focus {
                margin: 10px -1px 0 -1px;
            }
        }
    }
}

.wrong-email {
    color: $secondary-red;
    font-size: 12px;
    letter-spacing: 0.34px;
    text-decoration: underline;
    cursor: pointer;
}
</style>
